<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!estimate">
      <span class="loader"></span>
    </span>
    <div class="elite-tabs-wrapper" v-else>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper tabs-wrapper-nav"
        tabNavClasses="nav elite-tabs"
        :value="getActiveTab()"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="false">
          <span slot="title">
            <i class="fa-regular fa-list"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <div class="tab-pane-inner"></div>
          <estimate-view-global
            :estimate="estimate"
            @onCancelEstimate="onCancelEstimate"
            @onEditEstimate="onEditEstimate"
            @onViewEstimate="onViewEstimate"
          />
        </tab-pane>

        <tab-pane title="images" id="2" :active="false">
          <span slot="title">
            <i class="fa-regular fa-image"></i>
            {{ $t("COMMON.PICTURES") }}
          </span>
          <estimate-view-images :estimate="estimate" />
        </tab-pane>

        <tab-pane
          title="inspection"
          id="3"
          :active="false"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS) &&
            !!estimate.inspection
          "
        >
          <span slot="title">
            <i class="fa-regular fa-clipboard-check"></i>
            {{ $t("COMMON.INSPECTION") }}
          </span>
          <div class="tab-pane-inner"></div>
          <estimate-view-inspection
            :estimate="estimate"
            @onViewEstimate="onViewEstimate"
          />
        </tab-pane>

        <tab-pane
          title="repair"
          id="4"
          :active="false"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_REPAIRS) &&
            !!estimate.repair
          "
        >
          <span slot="title">
            <i class="fa-regular fa-wrench"></i>
            {{ $t("COMMON.REPAIR") }}
          </span>
          <div class="tab-pane-inner"></div>
          <estimate-view-repair
            :estimate="estimate"
            @onViewEstimate="onViewEstimate"
          />
        </tab-pane>

        <tab-pane
          :active="false"
          id="5"
          title="estimate"
          v-if="estimate.status != ESTIMATE_STATUS_DRAFT"
        >
          <span class="title" slot="title">
            <i class="far fa-usd-circle"></i>
            {{ $t("COMMON.ESTIMATE") }}
          </span>
          <div class="tab-pane-inner">
            <estimate-view-estimate
              :estimateData="estimate"
              :loading="isLoading"
              :hourlyRateValue="hourlyRate"
              @estimateUpdated="estimateUpdated"
              @onCloseEstimateModal="onCloseEstimateModal"
              @onCancelEstimate="onCancelEstimate"
              @onStartComponentsEstimate="onStartComponentsEstimate"
              @onEndComponentsEstimate="onEndComponentsEstimate"
              @onStartTimesEstimate="onStartTimesEstimate"
              @onEndTimesEstimate="onEndTimesEstimate"
              @onEndEstimate="onEndEstimate"
              @onStartApproveEstimate="onStartApproveEstimate"
              @onEndApproveEstimate="onEndApproveEstimate"
              @onUpdateEstimate="updateEstimate"
              @onTakeOverComponentsEstimate="onTakeOverComponentsEstimate"
              @onTakeOverTimesEstimate="onTakeOverTimesEstimate"
              :key="estimateViewEstimateKey"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="vehicle"
          id="6"
          :active="false"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
            !!estimate.vehicle
          "
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-car"></i>
            {{ $t("COMMON.VEHICLE") }}
          </span>
          <div class="tab-pane-inner">
            <estimate-view-vehicle
              :estimate="estimate"
              @onUpdateEstimate="updateEstimate"
              @onViewEstimate="onViewEstimate"
            />
          </div>
        </tab-pane>

        <tab-pane
          title="client"
          id="7"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)"
        >
          <span class="title" slot="title">
            <i class="fa-regular fa-user"></i>
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <estimate-view-customer
            :estimate="estimate"
            @onUpdateEstimate="updateEstimate"
          />
        </tab-pane>

        <tab-pane
          title="files"
          id="8"
          :active="false"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_FILES)"
        >
          <span class="title" slot="title">
            <i class="far fa-file-alt"></i>
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="estimate" />
          </div>
        </tab-pane>

        <tab-pane
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
          id="999"
          title="logs"
        >
          <span slot="title">
            <i class="fa fa-history"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <estimate-view-logs :estimate="estimate" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { ESTIMATE_STATUS_DRAFT } from "@/constants/estimates";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import EstimateViewEstimate from "../partials/EstimateViewEstimate.vue";
import EstimateViewLogs from "../partials/EstimateViewLogs.vue";
import EstimateViewGlobal from "../partials/EstimateViewGlobal.vue";
import EstimateViewVehicle from "../partials/EstimateViewVehicle.vue";
import EstimateViewCustomer from "../partials/EstimateViewCustomer.vue";
import EstimateViewInspection from "../partials/EstimateViewInspection.vue";
import EstimateViewRepair from "../partials/EstimateViewRepair.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import EstimateViewImages from "../partials/EstimateViewImages.vue";
import { mapGetters } from "vuex";
import hasTabs from "@/mixins/has-tabs";
import alertLeaveMixin from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EstimateViewEstimate,
    EstimateViewLogs,
    EstimateViewVehicle,
    EstimateViewGlobal,
    EstimateViewCustomer,
    EstimateViewInspection,
    EstimateViewRepair,
    EstimateViewImages,
    ListFileComponent,
  },

  mixins: [
    alertLeaveMixin,
    requestErrorMixin,
    estimatePermissionsMixin,
    hasTabs,
  ],

  props: {
    estimateId: {
      type: [String, Number],
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const isLoading = this.loading ?? false;
    return {
      alertLeave: true,
      estimate: null,
      ESTIMATE_STATUS_DRAFT,
      isLoading,
      hourlyRate: 0,
      estimateViewEstimateKey: 1,
    };
  },

  computed: {
    // canViewEstimate() {
    //   return this.estimate?.status != ESTIMATE_STATUS_DRAFT;
    // },
    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),
  },

  async created() {
    await this.get();

    if (this.estimate?.organization) {
      await this.$store.dispatch("organizationOptions/getOrganizationConfig", {
        id: this.estimate.organization.id,
        allowedLocations: this.estimate.allowedLocations.map(
          (location) => location.id
        ),
      });
      this.hourlyRate =
        this.organizationConfig.DEFAULT_ESTIMATION_ITEM_HOURLY_RATE ?? 0;
      this.estimateViewEstimateKey = this.estimateViewEstimateKey * 400;
    }

    swal.close();
  },

  methods: {
    async get() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("estimations/get", this.estimateId);
        this.estimate = this.$store.getters["estimations/estimation"];
        if (this.canViewEstimate(this.estimate)) {
          this.$emit("estimateLoaded", this.estimate);
        } else {
          this.$emit("onEditEstimate", this.estimate);
        }
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async estimateUpdated() {
      await this.get();
      this.$emit("estimateUpdated", true);
    },

    onCloseEstimateModal() {
      this.$emit("onCloseEstimateModal", true);
    },

    onEditEstimate(estimate = null) {
      if (!estimate) {
        estimate = this.estimate;
      }

      this.$emit("onEditEstimate", this.estimate);
    },

    onViewEstimate() {
      this.$emit("onViewEstimate", this.estimate);
    },

    async onCancelEstimate() {
      await this.get();

      this.$emit("onCancelEstimate", this.estimate);
    },

    async onStartComponentsEstimate() {
      await this.startComponentsEstimate();

      await this.get();

      // this.$emit("onStartComponentsEstimate", this.estimate);
    },

    async startComponentsEstimate() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(
          "estimations/startComponents",
          this.estimate.id
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_COMPONENTS"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndComponentsEstimate() {
      await this.get();

      this.$emit("onEndComponentsEstimate", this.estimate);
    },

    async onStartTimesEstimate() {
      await this.startTimesEstimate();

      await this.get();
      // this.$emit("onStartTimesEstimate", this.estimate);
    },

    async startTimesEstimate() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("estimations/startTimes", this.estimate.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_TIMES"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndTimesEstimate() {
      await this.get();

      this.$emit("onEndTimesEstimate", this.estimate);
    },

    async onEndEstimate() {
      await this.get();

      this.$emit("onEndEstimate", this.estimate);
    },

    async onStartApproveEstimate() {
      await this.startApproveEstimate();

      await this.get();
      // this.$emit("onStartApproveEstimate", this.estimate);
    },

    async startApproveEstimate() {
      this.isLoading = true;

      try {
        await this.$store.dispatch(
          "estimations/startApprove",
          this.estimate.id
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_STARTED_APPROVE"),
        });
      } catch (error) {
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onEndApproveEstimate() {
      await this.get();

      this.$emit("onEndApproveEstimate", this.estimate);
    },

    async updateEstimate(estimateData) {
      this.isLoading = true;
      swal.showLoading();
      try {
        await this.$store.dispatch("estimations/update", estimateData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_UPDATED"),
        });

        await this.get();

        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.isLoading = false;
      }
    },

    onTakeOverComponentsEstimate(data) {
      this.$emit("onTakeOverComponentsEstimate", this.estimate, data);
    },

    onTakeOverTimesEstimate(data) {
      this.$emit("onTakeOverTimesEstimate", this.estimate, data);
    },

    getActiveTab() {
      if (
        this.canStartComponentsEstimate(this.estimate) ||
        this.canEndComponentsEstimate(this.estimate) ||
        this.canStartTimesEstimate(this.estimate) ||
        this.canEndTimesEstimate(this.estimate) ||
        this.canStartApproveEstimate(this.estimate) ||
        this.canEndApproveEstimate(this.estimate)
      ) {
        return "estimate";
      }
      return this.currentTab;
    },
  },

  watch: {
    loading(value) {
      this.isLoading = value;
    },
  },
};
</script>
