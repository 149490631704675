<template>
  <form ref="itemForm" class="list-item" :id="itemId ? `item-${itemId}` : ''">
    <div class="list-item-line">
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          v-model="itemModel.name"
          :label="`${$t('ESTIMATES.ESTIMATE_ITEM')} (*)`"
          :placeholder="$t('ESTIMATES.ESTIMATE_ITEM')"
          :name="$t('ESTIMATES.ESTIMATE_ITEM')"
          :disabled="!canEditItems || item.is_linked_to_inspection"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />
      </div>
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          v-model="itemModel.excerpt"
          :label="`${$t('ESTIMATES.ESTIMATE_ITEM_DETAIL')}`"
          :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_DETAIL')"
          :disabled="!canEditItems || item.is_linked_to_inspection"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
      <div class="list-item-line-group flex-column align-items-stretch">
        <base-input
          :label="`${$t('ESTIMATES.ESTIMATE_ITEM_STATE')} (*)`"
          :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_STATE')"
          :disabled="!canEditItems"
          vid="component_condition"
        >
          <el-select
            v-model="itemModel.component_condition"
            :disabled="!canEditItems || !item.component_condition_editable"
            :placeholder="
              itemModel.component_condition
                ? ''
                : $t('ESTIMATES.ESTIMATE_ITEM_STATE')
            "
          >
            <el-option
              v-for="[key, condition] in Object.entries(
                conditionOptionsDisplay
              )"
              class="custom-select-option"
              :value="key"
              label=" "
              :key="key"
            >
              <div class="option-inner">
                <span class="icon" :class="condition?.color">
                  <i class="far" :class="condition?.iconName"></i>
                </span>
                <span class="text">{{ $t(`ESTIMATES.${key}`) }}</span>
              </div>
            </el-option>
            <template v-if="itemModel.component_condition" #prefix>
              <div class="option-inner prefix">
                <span
                  class="icon"
                  :class="
                    conditionOptionsDisplay[itemModel.component_condition]
                      ?.color
                  "
                >
                  <i
                    class="far"
                    :class="
                      conditionOptionsDisplay[itemModel.component_condition]
                        ?.iconName
                    "
                  ></i>
                </span>
                <span class="text">{{
                  $t(`ESTIMATES.${itemModel.component_condition}`)
                }}</span>
              </div>
            </template>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.component_condition" />
      </div>
      <div class="list-item-line-group buttons align-self-end" v-if="itemId">
        <base-button
          class="add-image position-relative"
          icon
          size="sm"
          @click="showPictureModal = true"
        >
          <i class="far fa-camera"></i>
          <span class="badge d-inline-block" v-if="itemModel.gallery.length" />
        </base-button>
        <base-button
          class="add-comment"
          icon
          size="sm"
          @click="commentSectionModalOpened = true"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_COMMENTS) ||
            $currentUserCan($permissions.PERM_VIEW_COMMENTS)
          "
        >
          <i class="far fa-comment"></i>
          <span class="badge" v-if="itemModel.comments_count">
            {{ itemModel.comments_count }}
          </span>
        </base-button>
      </div>
    </div>
    <div class="list-item-line labor">
      <div
        v-for="(component, index) in components"
        class="part-labor"
        v-if="!withoutComponents"
        :key="index"
      >
        <div class="part-labor-item">
          <base-input
            v-model="component.component_name"
            :label="`${$t('ESTIMATES.ESTIMATE_ITEM_PART')}`"
            :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_PART')"
            :name="$t('ESTIMATES.ESTIMATE_ITEM_PART')"
            :disabled="!canEditComponents"
          />
          <validation-error :errors="apiValidationErrors.component_name" />
        </div>
        <div class="part-labor-item">
          <base-input
            v-model="component.component_code"
            :label="`${$t('ESTIMATES.ESTIMATE_ITEM_PART_NUMBER')}`"
            :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_PART_NUMBER')"
            :disabled="!canEditComponents"
          />
          <validation-error :errors="apiValidationErrors.component_code" />
        </div>
        <div class="part-labor-item small">
          <base-input
            v-model.number="component.component_quantity"
            :label="`${$t('COMMON.QUANTITY')}`"
            :placeholder="$t('COMMON.QUANTITY')"
            :name="`${$t('COMMON.QUANTITY')}`"
            :disabled="!canEditComponents"
            clear-zero
            type="number"
            min="0"
          />
          <validation-error :errors="apiValidationErrors.component_quantity" />
        </div>
        <div class="part-labor-item small clear-input-number-arrows">
          <base-input
            v-model.number="component.component_amount"
            :label="`${$t('COMMON.PRICE')}`"
            :placeholder="$t('COMMON.PRICE')"
            :name="`${$t('COMMON.PRICE')}`"
            :disabled="!canEditComponents"
            append-icon="$"
            input-classes="border-right-0 p-2"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            min="0"
            step="0.01"
            type="number"
            clear-zero
          >
            <template #append><span class="text-black">$</span></template>
          </base-input>
          <validation-error :errors="apiValidationErrors.component_amount" />
        </div>
        <div class="part-labor-item small sub-total">
          <base-input
            :value="componentsAmount[index]?.toFixed(2)"
            disabled
            :label="`${$t('COMMON.SUBTOTAL')}`"
            :placeholder="$t('COMMON.SUBTOTAL')"
            append-icon="$"
            input-classes="border-right-0 p-2"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            min="0"
            step="0.01"
          >
            <template #append><span class="text-black">$</span></template>
          </base-input>
        </div>
        <div
          class="part-labor-item small align-content-end"
          v-if="canEditComponents"
        >
          <base-button
            type="button"
            class="form-control btn btn-danger"
            @click.prevent="() => removeComponent(index)"
          >
            <i class="fal fa-times"></i>
          </base-button>
        </div>
      </div>
      <div v-if="!withoutComponents && canEditComponents">
        <base-button
          type="button"
          class="btn add"
          @click.prevent="addComponent"
        >
          <i class="far fa-plus"></i>
          {{ $t("COMMON.ADD") }}
        </base-button>
      </div>
      <div class="part-labor">
        <div class="part-labor-item">
          <base-input
            v-model="itemModel.component_details"
            :label="`${$t('ESTIMATES.ESTIMATE_ITEM_MANUAL_LABOR')}`"
            :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_MANUAL_LABOR')"
            :disabled="!canEditTimes"
          />
          <validation-error
            :errors="
              apiValidationErrors.component_details ??
              componentDetailsRequiredErrorMessage
            "
          />
        </div>
        <div class="part-labor-item small clear-input-number-arrows">
          <base-input
            v-model.number="itemModel.repair_time_hour"
            :label="`${$t('ESTIMATES.ESTIMATE_ITEM_TIME')}`"
            :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_TIME')"
            :disabled="!canEditTimes"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            type="number"
            append-icon="h"
            input-classes="border-right-0 p-2"
            min="0"
            step="0.01"
            clear-zero
          >
            <template #append><span class="text-black">h</span></template>
          </base-input>
          <validation-error :errors="apiValidationErrors.repair_time_hour" />
        </div>
        <div class="part-labor-item small clear-input-number-arrows">
          <base-input
            v-model.number="itemModel.work_amount"
            :disabled="!canEditTimes"
            :label="`${$t('ESTIMATES.ESTIMATE_ITEM_HOURLY_RATE')}`"
            :placeholder="$t('ESTIMATES.ESTIMATE_ITEM_HOURLY_RATE')"
            append-icon="h"
            input-classes="border-right-0 p-2"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            min="0"
            step="0.01"
            type="number"
          >
            <template #append><span class="text-black">$</span></template>
          </base-input>
          <validation-error :errors="apiValidationErrors.work_amount" />
        </div>
        <div class="part-labor-item small sub-total">
          <base-input
            :value="workAmount?.toFixed(2)"
            disabled
            :label="`${$t('COMMON.SUBTOTAL')}`"
            :placeholder="$t('COMMON.SUBTOTAL')"
            append-icon="h"
            input-classes="border-right-0 p-2"
            input-group-classes="text-black border-2 purge-append-border input-group-text-p-2"
            min="0"
            step="0.01"
          >
            <template #append><span class="text-black">$</span></template>
          </base-input>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-2">
      <div class="w-40 h-100">
        <base-button
          type="button"
          class="btn-outline-darker"
          @click="newItem"
          v-if="isLastItem"
        >
          <i class="fas fa-plus mr-1"></i> {{ $t("COMMON.ADD") }}
        </base-button>
        <base-button
          type="button"
          class="btn-danger"
          v-if="canRemoveItem"
          @click="removeItem"
        >
          <i class="fas fa-close mr-1"></i> {{ $t("COMMON.REMOVE") }}
        </base-button>
        <div class="d-inline-block" v-if="loading">
          <div class="custom-loading-icon"></div>
        </div>
      </div>
      <div
        class="list-item-line total w-60 p-2"
        :class="[canShowStatus ? item.status : 'bg-transparent']"
      >
        <div class="radio-group" v-if="canShowStatus">
          <base-radio
            v-for="[key, status] in Object.entries(statusOptions)"
            :value="status"
            :name="status"
            v-if="canEditStatus"
            v-model="itemModel.status"
            :key="status"
          >
            {{ $t(`ESTIMATES.${key}`) }}
            <span class="check"></span>
          </base-radio>
          <span
            class="check font-italic font-weight-bold text-black"
            v-if="!canEditStatus && itemModel.status"
          >
            {{ $t(`COMMON.${itemModel.status}`) }}
          </span>
          <validation-error :errors="apiValidationErrors.status" />
        </div>
        <div class="item-total ml-auto">
          <ul>
            <li>{{ $t("COMMON.TOTAL") }} :</li>
            <li>{{ $formatCurrency(total) }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex justify-content-end mt-2" v-if="canRemoveItem">
      <base-button type="button" class="btn btn-danger" @click="deleteItem">
        {{ $t("ESTIMATES.REMOVE_ITEM") }}
      </base-button>
    </div> -->
    <div
      class="alert mt-3 text-black"
      role="alert"
      style="background-color: rgba(var(--primary-rgb), 0.2)"
      v-html="$t('ESTIMATES.REVIEW_ALERT')"
      v-if="showReviewAlert"
    />

    <inspection-confirmation-modal
      :confirm-action="deleteItem"
      :confirm-button-text="$t('COMMON.YES_DELETE')"
      :loading="loading"
      :message="$t('ESTIMATES.DELETE_THIS_ITEM_FROM_ESTIMATE')"
      :open.sync="showDeleteItemModal"
      modal-classes="modal-secondary cancel"
    />

    <modal
      :showClose="true"
      :show.sync="showPictureModal"
      modal-classes="picture"
    >
      <template slot="header">
        <h5 class="modal-title" id="inspection-picture">
          {{ $t("INSPECTIONS.ADD_PICTURES") }}
        </h5>
      </template>
      <div>
        <gallery-selector
          :label="$t('COMMON.PICTURES')"
          :defaultGallery="gallery"
          ressource_name="estimation_items"
          :ressource_id="itemId"
          :field="itemModel.name"
          :disabled="!canEditItems"
          @galleryChanged="
            (gallery_urls) => {
              gallery = gallery_urls;
            }
          "
        />
      </div>
      <template slot="footer" v-if="canEditItems">
        <!-- <base-button
          type="secondary"
          class="btn close"
          @click="showPictureModal = false"
        >
          {{ $t("COMMON.CLOSE") }}
        </base-button> -->
        <base-button type="primary" class="btn save" @click="addImages">
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </template>
    </modal>
    <comments-modal
      :open.sync="commentSectionModalOpened"
      :object="itemModel"
      @onCommentsChanged="get"
      :canAddComment="canEditStatus || canEditComponents || canEditTimes"
    />
  </form>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import {
  estimateItemConditionsOptionsDisplay,
  estimateItemStatusesOption,
  ESTIMATE_ITEM_STATUS_APPROVED,
  ESTIMATE_ITEM_CONDITION_TO_REPLACE,
  ESTIMATE_ITEM_STATUS_DENIED,
  ESTIMATE_ITEM_STATUS_REVIEWED,
} from "@/constants/estimateItems";
import formMixin from "@/mixins/form-mixin";
import BaseRadio from "@/components/Inputs/BaseRadio.vue";
import _ from "lodash";
import swal from "sweetalert2";
import ValidationError from "@/components/ValidationError.vue";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import CommentsModal from "@/components/Comments/CommentsModal.vue";
import { cloneDeep, isEqual, debounce } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import defaultEstimationItem from "../defaultEstimationItem";

export default {
  name: "estimate-item-form",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    BaseRadio,
    ValidationError,
    InspectionConfirmationModal,
    GallerySelector,
    CommentsModal,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      default: false,
    },
    canShowStatus: {
      type: Boolean,
      default: false,
    },
    canRemoveItem: {
      type: Boolean,
      default: false,
    },
    canEditComponents: {
      type: Boolean,
      default: false,
    },
    canEditTimes: {
      type: Boolean,
      default: false,
    },
    isLastItem: {
      type: Boolean,
      default: false,
    },
    withoutComponents: {
      type: Boolean,
      default: false,
    },
    withoutTimes: {
      type: Boolean,
      default: false,
    },
    canShowReviewAlert: {
      type: Boolean,
      default: false,
    },
    showComponentDetailsRequiredErrorMessage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let estimation = { ...this.item.estimation };
    let itemId = this.item?.id;
    let itemModel = { ...this.item };
    const backupData = { ...itemModel };
    const gallery = itemModel.gallery;
    const components = itemModel.components;
    return {
      itemId,
      itemModel,
      backupData,
      estimation,
      conditionOptionsDisplay: estimateItemConditionsOptionsDisplay,
      statusOptions: estimateItemStatusesOption,
      ESTIMATE_ITEM_CONDITION_TO_REPLACE,
      ESTIMATE_ITEM_STATUS_APPROVED,
      loading: false,
      formErrors: [],
      showDeleteItemModal: false,
      showPictureModal: false,
      commentSectionModalOpened: false,
      gallery,
      components,
    };
  },

  computed: {
    componentsAmount: function () {
      return (
        this.itemModel.components?.map((component) =>
          Number(
            (
              component.component_quantity * component.component_amount
            )?.toFixed(2)
          )
        ) ?? []
      );
    },
    componentAmount: function () {
      if (this.withoutComponents) {
        return 0;
      }
      return this.componentsAmount.reduce((acc, val) => acc + val, 0);
    },
    workAmount: function () {
      if (this.withoutTimes) {
        return 0;
      }
      return Number(
        (this.itemModel.repair_time_hour * this.itemModel.work_amount)?.toFixed(
          2
        )
      );
    },
    total: function () {
      return this.componentAmount + this.workAmount;
    },
    readOnly: function () {
      return (
        !this.canEditComponents && !this.canEditTimes && !this.canEditStatus
      );
    },
    canEditItems: function () {
      return this.canEditComponents || this.canEditTimes;
    },
    sendDataFiltered: function () {
      const item = { ...this.itemModel };
      if (this.itemId) {
        for (const [key, value] of Object.entries(item)) {
          if (value == this.backupData[key]) {
            delete item[key];
          }
        }
      }

      return { ...item };
    },
    showReviewAlert: function () {
      return (
        this.canShowReviewAlert &&
        this.itemModel.status == ESTIMATE_ITEM_STATUS_REVIEWED
      );
    },

    componentDetailsRequiredErrorMessage: function () {
      if (!this.showComponentDetailsRequiredErrorMessage) {
        return [];
      }
      return [this.$t("validations.messages.required")];
    },
  },

  created: function () {},

  beforeDestroy: function () {},

  mounted: function () {},

  methods: {
    openCommentModal() {
      this.$emit("openCommentModal");
    },

    newItem() {
      this.$emit("addNewItem");
    },

    async saveItem() {
      this.formErrors = [];

      const sendDataFiltered = cloneDeep(this.sendDataFiltered);
      delete sendDataFiltered.type;
      delete sendDataFiltered.relationshipNames;
      delete sendDataFiltered.estimation;
      delete sendDataFiltered.organization;
      delete sendDataFiltered.links;

      if (!Object.keys(sendDataFiltered).length) return;

      const item = {
        type: "estimation-items",
        relationshipNames: ["estimation", "organization"],
        estimation: this.estimation,
        organization: this.backupData.organization,
        ...this.sendDataFiltered,
      };

      if (this.itemId) {
        this.updateItem(item);
      } else {
        this.addItem(item);
      }
    },

    async addItem(item) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("estimationItems/add", item);
        let resultFiltered = { ...result };
        this.itemId = result.id;

        for (const key of Object.keys(item)) {
          delete resultFiltered[key];
        }

        this.itemModel = {
          ...this.itemModel,
          ...resultFiltered,
          ...this.sendDataFiltered,
        };
        this.backupData = { ...result, ...item };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },

    async updateItem(item) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("estimationItems/update", {
          ...item,
          id: this.itemId,
        });
        let resultFiltered = { ...result };

        for (const key of Object.keys(item)) {
          delete resultFiltered[key];
        }

        this.itemModel = {
          ...this.itemModel,
          ...resultFiltered,
          ...this.sendDataFiltered,
        };
        this.backupData = { ...result, ...item };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    async get() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch(
          "estimationItems/get",
          this.itemId
        );

        this.itemModel = { ...this.itemModel, ...result };
        this.backupData = { ...this.itemModel, ...result };
        this.$emit("itemUpdated");
      } catch (error) {
        // this.$notify({
        //   type: "danger",
        //   message: "An error occurred while updating",
        // });
        this.formErrors = error.response.data.errors;
      } finally {
        this.loading = false;
      }
    },

    removeItem() {
      if (this.itemId) {
        this.showDeleteItemModal = true;
      } else {
        this.$emit("deleteItem");

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ITEM_DELETED"),
        });
      }
    },

    async deleteItem() {
      try {
        this.loading = true;
        swal.showLoading();

        await this.$store.dispatch("estimationItems/destroy", this.itemId);

        this.$emit("deleteItem");

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("ESTIMATES.ESTIMATE_ITEM_DELETED"),
        });
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error, false);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.showDeleteItemModal = false;
        this.loading = false;
      }
    },
    addImages() {
      this.itemModel = { ...this.itemModel, gallery: this.gallery };
      this.$nextTick(function () {
        this.showPictureModal = false;
      });
    },

    saveItemDebounced: debounce(function () {
      this.saveItem();
    }, 500),

    addComponent() {
      // const item = cloneDeep(this.itemModel);
      this.components.push(cloneDeep(defaultEstimationItem.components[0]));
      // this.itemModel = item;
    },
    removeComponent(index) {
      // const item = cloneDeep(this.itemModel);
      this.components.splice(index, 1);
      // this.itemModel = item;
    },
  },

  watch: {
    itemModel: {
      handler: function (newValue, oldValue) {
        if (this.readOnly) return;

        this.$emit("update:item", { ...newValue, id: this.itemId });

        if (
          [ESTIMATE_ITEM_STATUS_DENIED, ESTIMATE_ITEM_STATUS_REVIEWED].includes(
            newValue.status
          ) &&
          newValue.status != this.backupData.status
        ) {
          this.commentSectionModalOpened = true;
        }

        if (newValue.status != this.backupData.status) {
          this.saveItem();
        } else {
          this.saveItemDebounced();
        }
      },
      deep: true,
    },
    components: {
      handler: debounce(function (components) {
        this.itemModel.components = cloneDeep(components);
      }, 300),
      deep: true,
    },
    item: {
      handler: function () {
        const newObject = cloneDeep(this.item);
        const oldObject = cloneDeep(this.itemModel);

        delete oldObject.relationshipNames;
        delete oldObject.estimation;
        delete oldObject.organization;
        delete oldObject.links;

        delete newObject.relationshipNames;
        delete newObject.estimation;
        delete newObject.organization;
        delete newObject.links;

        if (isEqual(newObject, oldObject)) return;

        this.itemModel = { ...this.itemModel, ...newObject };
      },
      deep: true,
    },
    // item() {
    //   this.itemModel = cloneDeep(this.item);
    // },
    formErrors(errors) {
      this.setApiValidation(errors);
    },
    showPictureModal(value) {
      if (!value) {
        this.gallery = this.itemModel.gallery;
      }
    },
  },
};
</script>
<style lang="scss">
.w-60 {
  width: 60% !important;
}
.w-40 {
  width: 40% !important;
}
.clear-input-number-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
.input-group-text-p-2 {
  .input-group-text {
    padding: 0.5rem !important;
  }
}
.text-black {
  color: #000 !important;
}
.estimate-details {
  .form-group {
    .input-group-text {
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      // border-top-right-radius: 8px;
      // border-bottom-right-radius: 8px;
      border-radius: 0 8px 8px 0;
    }
  }
}
.list-item-line.total.APPROVED {
  background-color: var(--complete-color);
}
.list-item-line.total.DENIED {
  background-color: var(--canceled-color);
}
.list-item-line.total.REVIEWED {
  background-color: var(--reviewed-color);
}
</style>
