<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header" v-if="addSection">
      <div class="search">
        <div
          v-if="$currentUserCan($permissions.PERM_CREATE_ESTIMATIONS)"
          class="search-add"
        >
          <base-button class="add" icon size="sm" @click="addEstimate">
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("ESTIMATES.ADD_ESTIMATE") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>
        <div>
          <base-button
            class="notification"
            type="info"
            icon
            size="sm"
            :title="$t('COMMON.SHOW_DELETED_ITEMS')"
            :class="{ active: onlyTrashed }"
            @click="onlyTrashed = !onlyTrashed"
          >
            <span class="btn-inner--icon">
              <i class="fa-light fa-trash"></i>
            </span>
          </base-button>
        </div>

        <!--<notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_ESTIMATIONS)"
          :objectType="'estimations'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>

          <div
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            class="advanced-search-item"
          >
            <organization-selector
              :organization="selectedOrganization"
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>

          <div class="advanced-search-item">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="$t('COMMON.STATUS') + '...'"
                :value="null"
              />
              <el-option
                v-for="value in estimateStatusesOption"
                class="select-primary"
                :key="value"
                :label="$t(`ESTIMATES.ESTIMATE_STATUS_${value}`)"
                :value="value"
              />
            </el-select>
          </div>
          <div class="advanced-search-item date">
            <date-range-selector
              :value="selectedDateRange"
              @onDateRangeChanged="
                (dates) => {
                  selectedDateRange = dates;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS) && false"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.REVIEWED_BY')"
              @userChanged="(userId) => (selectedReviewedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS) && false"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.COMPLETED_BY')"
              @userChanged="(userId) => (selectedCompletedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
              false
            "
          >
            <user-selector
              :placeholder="$t('ESTIMATES.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCustomer &&
              $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS) &&
              false
            "
          >
            <customer-selector
              :disabled="false"
              @customerChanged="(customerId) => (selectedCustomer = customerId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <years-selector
              :filterable="true"
              :value="selectedVehicleModelYear"
              allow-none
              @yearsChanged="
                (year) => {
                  selectedVehicleModelYear = year;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <make-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :placeholder="$t('VEHICLES.MAKE')"
              :value="selectedVehicleMake"
              @makeChanged="
                (make) => {
                  selectedVehicleMake = make;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <model-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :make="selectedVehicleMake"
              :value="selectedVehicleModel"
              @modelChanged="
                (model) => {
                  selectedVehicleModel = model;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
              false
            "
          >
            <serie-selector
              allow-none
              :year="selectedVehicleModelYear"
              :model="selectedVehicleModel"
              :filterable="true"
              :value="selectedVehicleSeries"
              @serieChanged="
                (serie) => {
                  selectedVehicleSeries = serie;
                }
              "
            />
          </div>

          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS) && false
            "
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATION')}`">
              <location-selector
                @locationChanged="(location) => (selectedLocation = location)"
              />
            </base-input>
          </div>

          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS) && false
            "
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.INSPECTION')}`">
              <inspection-selector
                @inspectionChanged="
                  (inspection) => (selectedInspection = inspection)
                "
              />
            </base-input>
          </div>

          <div class="advanced-search-item d-inline-flex">
            <base-input
              :placeholder="$t('COMMON.ODOMETER_FROM')"
              v-model.number="odometer.from"
              type="number"
            />
            <base-input
              :placeholder="$t('COMMON.ODOMETER_TO')"
              v-model.number="odometer.to"
              type="number"
            />
            <!-- <el-select
              v-model="odometer_unit"
              :placeholder="$t('VEHICLES.ODOMETER_UNIT')"
            >
              <el-option value="km" :label="$t('ODOMETERS.KM')" />
              <el-option value="miles" :label="$t('ODOMETERS.MILES')" />
            </el-select> -->
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm" @click="resetFilters">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.RESET") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>

    <estimate-table
      v-if="showInProgresses"
      :columns="columns"
      :filter-creator="selectedCreator ?? filterCreator"
      :filter-customer="selectedCustomer ?? filterCustomer"
      :filter-inspection="selectedInspection ?? filterInspection"
      :filter-organization="selectedOrganization ?? filterOrganization"
      :filter-status="ESTIMATE_STATUS_IN_PROGRESS"
      :filter-vehicle="selectedVehicle ?? filterVehicle"
      :header="$t('COMMON.IN_PROGRESS')"
      :query="query"
      :selected-date-range="selectedDateRange"
      :selected-location="selectedLocation"
      :selected-reviewed-by="selectedReviewedBy"
      :selected-vehicle-make="selectedVehicleMake"
      :selected-vehicle-model-year="selectedVehicleModelYear"
      :selected-vehicle-model="selectedVehicleModel"
      :selected-vehicle-series="selectedVehicleSeries"
      :show-pagination="false"
      :only-trashed="onlyTrashed"
      :filter-odometer="odometer"
      @onAddEstimate="addEstimate"
      @onDeleteEstimate="deleteEstimate"
      @onEditEstimate="editEstimate"
      @onViewEstimate="viewEstimate"
      ref="inProgressTable"
    />

    <estimate-table
      :columns="columns"
      :exclude-status="showAll ? null : ESTIMATE_STATUS_IN_PROGRESS"
      :filter-creator="selectedCreator ?? filterCreator"
      :filter-customer="selectedCustomer ?? filterCustomer"
      :filter-inspection="selectedInspection ?? filterInspection"
      :filter-organization="selectedOrganization ?? filterOrganization"
      :filter-status="showAll ? [] : selectedStatus"
      :filter-vehicle="selectedVehicle ?? filterVehicle"
      :per-page="pagination.perPage"
      :query="query"
      :selected-completed-by="selectedCompletedBy"
      :selected-date-range="selectedDateRange"
      :selected-location="selectedLocation"
      :selected-reviewed-by="selectedReviewedBy"
      :selected-vehicle-make="selectedVehicleMake"
      :selected-vehicle-model-year="selectedVehicleModelYear"
      :selected-vehicle-model="selectedVehicleModel"
      :selected-vehicle-series="selectedVehicleSeries"
      :show-pagination="showPagination"
      :show-tabs="showTabs"
      :only-trashed="onlyTrashed"
      :filter-odometer="odometer"
      @onAddEstimate="addEstimate"
      @onDeleteEstimate="deleteEstimate"
      @onEditEstimate="editEstimate"
      @onViewEstimate="viewEstimate"
      ref="estimateTable"
    />
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import {
  estimateStatusesOption,
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_INCLUDES,
  ESTIMATE_STATUS_IN_PROGRESS,
} from "@/constants/estimates";
import { BasePagination } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import EstimateStatusBadge from "./EstimateStatusBadge.vue";
import InspectionSelector from "@/components/InspectionSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import YearsSelector from "@/components/YearsSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import CopyElement from "@/components/CopyElement.vue";
import EstimateTable from "./EstimateTable.vue";

export default {
  name: "estimate-list-table",

  components: {
    BasePagination,
    NotificationSubscription,
    DateRangeSelector,
    CustomerSelector,
    OrganizationSelector,
    LocationSelector,
    UserSelector,
    InspectionSelector,
    EstimateStatusBadge,
    YearSelector,
    YearsSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    CopyElement,
    EstimateTable,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, estimatePermissionsMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
    filterCustomer: {
      type: String,
      default: null,
      description: "customer id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterCreator: {
      type: String,
      default: null,
      description: "Creator",
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    filterInspection: {
      type: String,
      default: null,
      description: "Inspection id",
    },
    showInProgresses: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array | null,
      default: () => [
        "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        "approver",
        "updated_at",
        "days",
      ],
    },
    addSection: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const selectedStatus = this.filterStatus ?? null;

    return {
      query: null,
      showAdvancedFilter: false,
      estimations: [],
      loading: true,
      selectedEstimationStatus: null,
      selectedStatus,
      estimateStatusesOption,
      selectedDateRange: null,
      selectedInspection: null,
      selectedCustomer: null,
      selectedLocation: null,
      selectedCreator: null,
      selectedOrganization: null,
      selectedCompletedBy: null,
      selectedVehicle: null,
      selectedReviewedBy: null,
      ESTIMATE_STATUS_DRAFT,
      selectedVehicleModelYear: null,
      selectedVehicleMake: null,
      selectedVehicleModel: null,
      selectedVehicleSeries: null,
      ESTIMATE_STATUS_IN_PROGRESS,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      onlyTrashed: false,
      odometer: { from: null, to: null },
    };
  },

  computed: {},

  created() {},

  methods: {
    async deleteEstimate(estimate) {
      if (!this.canDeleteEstimate(estimate)) {
        return;
      }
      this.$emit("onDeleteEstimate", estimate);
    },

    viewEstimate(estimate) {
      if (this.canEditEstimate(estimate)) {
        this.$emit("onEditEstimate", estimate);
      }
      if (!this.canViewEstimate(estimate)) {
        return;
      }
      this.$emit("onViewEstimate", estimate);
    },

    editEstimate(estimate) {
      if (!this.canEditEstimate(estimate)) {
        return;
      }
      this.$emit("onEditEstimate", estimate);
    },

    addEstimate() {
      this.$emit("onAddEstimate");
    },

    refreshTable() {
      this.$refs.estimateTable.getList();
      this.$refs.estimateTable.getCounters();
      this.$refs.inProgressTable.getList();
    },

    resetFilters() {
      this.query = null;
      this.estimations = [];
      this.selectedEstimationStatus = null;
      this.selectedStatus = this.filterStatus ?? null;
      this.selectedDateRange = null;
      this.selectedInspection = null;
      this.selectedCustomer = null;
      this.selectedLocation = null;
      this.selectedCreator = null;
      this.selectedOrganization = null;
      this.selectedCompletedBy = null;
      this.selectedVehicle = null;
      this.selectedReviewedBy = null;
      this.selectedVehicleModelYear = null;
      this.selectedVehicleMake = null;
      this.selectedVehicleModel = null;
      this.selectedVehicleSeries = null;
      this.onlyTrashed = false;
      this.odometer = { from: null, to: null };
      this.pagination = {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      };
    },
  },
};
</script>
