<template>
  <div class="elite-tabs-wrapper-content">
    <estimate-ref-standardized
      :estimate="estimate"
      :title="title"
      @vehicleChanged="$emit('onViewEstimate')"
    >
      <template #buttons>
        <button class="edit" @click="showInspection">
          <span> {{ $t("INSPECTIONS.SHOW_INSPECTION") }} </span>
          <i class="fa-light fa-eye"></i>
        </button>
      </template>
    </estimate-ref-standardized>

    <inspection-view-global
      class-name=""
      :inspection="estimate.inspection"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import EstimateRefStandardized from "@/components/EstimateRefStandardized.vue";
import InspectionViewGlobal from "../../InspectionManagement/partials/InspectionViewGlobal.vue";

export default {
  name: "estimate-view-inspection",

  components: { InspectionViewGlobal, EstimateRefStandardized },

  props: ["estimate"],

  data() {
    return {};
  },

  computed: {
    title: function () {
      return `${this.estimate.code} - ${this.$t("COMMON.INSPECTION")}`;
    },
  },

  created() {},

  methods: {
    showInspection() {
      this.$router.push(this.$objectViewRoute(this.estimate.inspection));
    },
  },

  mounted() {},

  watch: {},
};
</script>
